<template>
	<v-container fluid style="max-width: 1200px">
		<h2 class="text-center text-h3 mb-5">
			{{ $t('events.createEvent') }}
		</h2>

		<v-alert
			v-if="!company.subscriptions.active"
			border="top"
			elevation="8"
			type="warning"
			color="red darken-3"
			max-width="500"
			dark
			class="mx-auto text-uppercase text-center"
		>
			{{ $t('subscriptions.noActiveSubscription') }}
		</v-alert>
		<v-alert
			v-else-if="company.subscriptions.unpaid"
			border="top"
			elevation="8"
			type="warning"
			color="red darken-3"
			max-width="500"
			dark
			class="mx-auto text-uppercase text-center"
		>
			{{ $t('subscriptions.unpaidSubscription') }}
		</v-alert>
		<v-alert
			v-else-if="company.limits.events.remaining == 0"
			border="top"
			elevation="8"
			type="warning"
			color="red darken-3"
			max-width="500"
			dark
			class="mx-auto text-uppercase text-center"
		>
			{{ $t('events.noEventsRemaining', { max: company.limits.events.maxPurchased }) }}
		</v-alert>

		<v-stepper v-model="stepperIndex" alt-labels class="rounded-xl">
			<v-stepper-header>
				<v-stepper-step :complete="stepperIndex > 1" step="1" :rules="[() => (formTypeSubmitted ? validType : true)]">
					{{ $t('events.chooseType') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step :complete="stepperIndex > 2" step="2" :rules="[() => (formEventSubmitted ? validEvent : true)]">
					{{ $t('offers.mainInformation') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step step="3">
					{{ $t('auth.verify') }}
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card flat color="transparent">
						<v-card-text>
							<v-form ref="formType" v-model="validType">
								<v-autocomplete
									v-model="eventType"
									:items="eventTypes"
									:loading="loading"
									:search-input.sync="search"
									:filter="customFilter"
									hide-selected
									item-text="name"
									item-value="id"
									:label="$t('events.eventType')"
									:placeholder="`${$t('search.search')}...`"
									:rules="rules.eventType"
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-3"
									@change="$refs.formEvent.resetValidation()"
								>
									<template #no-data>
										<v-list-item v-if="search == null">
											<v-list-item-title>
												<strong>{{ $t('search.search') }}</strong>
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-else>
											<v-list-item-title>
												{{ $t('search.noResults') }}
											</v-list-item-title>
										</v-list-item>
									</template>
								</v-autocomplete>
							</v-form>
						</v-card-text>

						<v-card-text v-if="eventType">
							<p v-if="!eventType.enabled">
								{{ $t('events.typeNotEnabled') }}
							</p>
							<p>
								{{ eventType.description }}
							</p>
							<p>{{ $t('events.behavior') }}:</p>
							<p>{{ $t('events.eventBehavior1') }}</p>
							<p>{{ $t('events.eventBehavior2') }}</p>
							<template v-if="eventType.code == 'interview'">
								<p>{{ $t('events.interviewBehavior1') }}</p>
								<p>{{ $t('events.interviewBehavior2') }}</p>
							</template>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateType()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text :to="{ name: 'Events' }">
								{{ $t('offers.cancel') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-card flat color="transparent">
						<v-card-text>
							<v-form ref="formEvent" v-model="validEvent">
								<v-text-field
									v-model="title"
									:label="$t('newsfeed.title')"
									:rules="rules.title"
									prepend-inner-icon="mdi-format-title"
									outlined
									rounded
									hide-details="auto"
									class="pb-3"
								/>
								<v-row>
									<v-col cols="12" md="6" sm="12">
										<v-text-field
											v-model="location"
											:label="$t('events.place')"
											:rules="rules.location"
											prepend-inner-icon="mdi-map-marker-outline"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
										/>
									</v-col>
									<v-col cols="12" md="6" sm="12">
										<v-text-field
											v-model="website"
											:label="$t('companies.website')"
											:hint="$t('events.websiteHint')"
											persistent-hint
											:rules="rules.website"
											prepend-inner-icon="mdi-earth"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-model="capacity"
											:label="$t('events.capacity')"
											:hint="$t('events.capacityHint')"
											persistent-hint
											:rules="rules.capacity"
											placeholder="20"
											type="number"
											prepend-inner-icon="mdi-counter"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
										/>
									</v-col>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-if="eventType ? eventType.code == 'interview' : false"
											v-model="duration"
											:label="$t('offers.duration')"
											:hint="$t('events.durationInMinutes')"
											persistent-hint
											:rules="rules.duration"
											placeholder="10"
											type="number"
											prepend-inner-icon="mdi-clock"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
											@click="$refs.formEvent.resetValidation()"
										/>
									</v-col>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-if="eventType ? eventType.code == 'interview' : false"
											v-model="people"
											:label="$t('events.people')"
											:hint="$t('events.peopleInterviewHint')"
											persistent-hint
											:rules="rules.people"
											placeholder="1"
											type="number"
											prepend-inner-icon="mdi-users"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" sm="6">
										<v-menu
											v-model="menu.start"
											eager
											offset-overflow
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template #activator="{ on, attrs }">
												<v-text-field
													:value="startDateTimeText"
													:label="$t('offers.start')"
													:rules="rules.timestamp.start"
													prepend-inner-icon="mdi-calendar"
													outlined
													rounded
													hide-details="auto"
													class="pb-3"
													readonly
													v-bind="attrs"
													v-on="on"
												/>
											</template>
											<v-tabs v-model="startTab" grow>
												<v-tab key="startDateTab">
													<v-icon>mdi-calendar</v-icon>
												</v-tab>
												<v-tab key="startTimeTab">
													<v-icon>mdi-clock-outline</v-icon>
												</v-tab>
											</v-tabs>

											<v-tabs-items v-model="startTab">
												<v-tab-item key="startDateTab" eager>
													<v-date-picker v-model="timestamp.start.date" no-title scrollable @input="startTab = 1" />
												</v-tab-item>
												<v-tab-item key="startTimeTab" eager>
													<v-time-picker
														v-model="timestamp.start.time"
														format="24hr"
														@change="
															roundStartTime()
															menu.start = false
															startTab = 0
														"
													/>
												</v-tab-item>
											</v-tabs-items>
										</v-menu>
									</v-col>
									<v-col cols="12" sm="6">
										<v-menu
											v-model="menu.end"
											eager
											offset-overflow
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template #activator="{ on, attrs }">
												<v-text-field
													:value="endDateTimeText"
													:label="$t('cv.end')"
													:rules="rules.timestamp.end"
													prepend-inner-icon="mdi-calendar"
													outlined
													rounded
													hide-details="auto"
													class="pb-3"
													readonly
													v-bind="attrs"
													v-on="on"
												/>
											</template>
											<v-tabs v-model="endTab" grow>
												<v-tab
													key="endDateTab"
													v-if="eventType ? !['interview', 'conference', 'presentation', 'workshop', 'seminar'].includes(eventType.code) : true"
												>
													<v-icon>mdi-calendar</v-icon>
												</v-tab>
												<v-tab key="endTimeTab">
													<v-icon>mdi-clock-outline</v-icon>
												</v-tab>
											</v-tabs>

											<v-tabs-items v-model="endTab">
												<v-tab-item
													key="endDateTab"
													eager
													v-if="eventType ? !['interview', 'conference', 'presentation', 'workshop', 'seminar'].includes(eventType.code) : true"
												>
													<v-date-picker v-model="timestamp.end.date" no-title scrollable @input="endTab = 1" />
												</v-tab-item>
												<v-tab-item key="endTimeTab" eager>
													<v-time-picker
														v-model="timestamp.end.time"
														format="24hr"
														@change="
															roundEndTime()
															menu.end = false
															endTab = 0
														"
														@click="$refs.formEvent.resetValidation()"
													/>
												</v-tab-item>
											</v-tabs-items>
										</v-menu>
									</v-col>
								</v-row>
								<v-textarea
									v-model="description"
									:label="$t('courses.description')"
									:counter="descriptionMaxLength"
									:rules="rules.description"
									outlined
									rounded
									hide-details="auto"
									class="pb-3"
									auto-grow
								/>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateEvent()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="3">
					<v-card flat color="transparent">
						<v-card-text>
							<h5>{{ $t('offers.importantInformation') }}</h5>
							<p>{{ $t('offers.importantInformation1') }}</p>
							<p>{{ $t('offers.importantInformation2') }}</p>
							<p>{{ $t('offers.importantInformation3') }}</p>

							<v-row>
								<v-col cols="12" lg="6" sm="12">
									<h5>{{ $t('offers.otherCompanyNameTitle') }}</h5>
									<p>{{ $t('offers.otherCompanyNameText') }}</p>
									<v-form ref="formSupplementary" v-model="validSupplementary">
										<v-text-field
											v-model="companyName"
											:label="$t('auth.name')"
											:counter="nameMaxLength"
											:rules="rules.name"
											:placeholder="company.name"
											prepend-inner-icon="mdi-format-title"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
										/>
									</v-form>
								</v-col>

								<v-col cols="12" lg="6" sm="12">
									<h5>{{ $t('offers.otherLogoTitle') }}</h5>
									<p>{{ $t('offers.otherLogoText') }}</p>
									<v-form ref="formLogo" v-model="validLogo">
										<v-file-input
											v-model="logo"
											:label="$t('companies.logo')"
											outlined
											rounded
											hide-details="auto"
											class="pb-3"
											@change="onLogoUpload"
										/>
									</v-form>
									<div v-if="logoCompressed && logoUrl" class="text-center">
										<h3>{{ $t('explorer.readyToUpload') }}</h3>
										<p>{{ logo.name }}</p>
										<v-avatar size="160">
											<v-img :src="logoUrl" />
										</v-avatar>
									</div>
									<div v-else>
										<v-avatar size="160">
											<v-img :src="company.imgURL" />
										</v-avatar>
									</div>
								</v-col>
							</v-row>

							<v-card color="transparent" flat rounded="xl">
								<v-card-title>
									<span>{{ $t('offers.aboutTheOffer') }}</span>
								</v-card-title>
								<v-card-text>
									<h3 class="text-uppercase">{{ title }}</h3>
									<p class="body-2">{{ eventType ? eventType.name : 'Error' }}</p>
									<p><v-icon>mdi-map-marker</v-icon> {{ location }}</p>
									<p><v-icon>mdi-earth</v-icon> {{ website }}</p>
									<p><v-icon>mdi-account-group</v-icon>{{ $t('events.capacity') }}: {{ capacity }}</p>
									<p><v-icon>mdi-calendar</v-icon>{{ $t('offers.start') }}: {{ startDateTimeText }}</p>
									<p><v-icon>mdi-calendar</v-icon>{{ $t('cv.end') }}: {{ endDateTimeText }}</p>
								</v-card-text>
							</v-card>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="callCreateEvent()">
								{{ $t('offers.create') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Compressor from 'compressorjs'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

function roundTime(time, minutesToRound) {
	let [hours, minutes] = time.split(':')
	hours = parseInt(hours)
	minutes = parseInt(minutes)

	// Convert hours and minutes to time in minutes
	time = hours * 60 + minutes

	let rounded = Math.round(time / minutesToRound) * minutesToRound
	let rHr = '' + Math.floor(rounded / 60)
	let rMin = '' + (rounded % 60)

	return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0')
}

export default {
	data() {
		return {
			loading: false,
			formTypeSubmitted: false,
			formEventSubmitted: false,
			formSupplementary: false,
			validType: false,
			validEvent: false,
			validLogo: false,
			validSupplementary: false,
			stepperIndex: 1,
			search: null,
			eventType: null,
			title: '',
			location: '',
			website: '',
			capacity: null,
			description: '',
			menu: {
				start: false,
				end: false
			},
			startTab: 0,
			endTab: 0,
			timestamp: {
				start: {
					date: '',
					time: ''
				},
				end: {
					date: '',
					time: ''
				}
			},
			duration: null,
			people: null,
			logo: null,
			companyName: '',
			logoCompressed: null,
			logoUrl: null,
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				location: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.locationMaxLength) || this.$t('rules.length', { length: this.locationMaxLength })
				],
				website: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.websiteMaxLength) || this.$t('rules.length', { length: this.websiteMaxLength })
				],
				capacity: [(v) => (!!v && v > 0) || this.$t('rules.required')],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				],
				timestamp: {
					start: [() => (!!this.timestamp.start.date && !!this.timestamp.start.time) || this.$t('rules.required')],
					end: [
						() => {
							return ['interview', 'conference', 'presentation', 'workshop', 'seminar'].includes(this.eventType.code)
								? !!this.timestamp.end.time || this.$t('rules.required')
								: (!!this.timestamp.end.date && !!this.timestamp.end.time) || this.$t('rules.required')
						},
						() => this.endDateTimeText >= this.startDateTimeText || this.$t('rules.timestampEnd'),
						() => {
							return ['interview'].includes(this.eventType.code) ? this.timespanDivisible || this.$t('rules.timespanDivisible') : true
						}
					]
				},
				eventType: [
					(v) => !!v || this.$t('rules.required'),
					(v) =>
						(v &&
							this.eventTypes
								.filter((e) => e.enabled)
								.map(({ id }) => id)
								.includes(v.id)) ||
						this.$t('rules.notSupported')
				],
				duration: [
					(v) => {
						return this.eventType.code == 'interview' ? (!!v && v > 0) || this.$t('rules.required') : true
					},
					(v) => {
						return this.eventType.code == 'interview' ? 60 % v == 0 || this.$t('rules.durationDivisor') : true
					}
				],
				people: [
					(v) => {
						return this.eventType.code == 'interview' ? (!!v && v > 0) || this.$t('rules.required') : true
					}
				],
				name: [(v) => 0 < v.length <= this.nameMaxLength || this.$t('rules.length', { length: this.nameMaxLength })]
			}
		}
	},
	computed: {
		titleMaxLength() {
			return 255
		},
		locationMaxLength() {
			return 255
		},
		websiteMaxLength() {
			return 1024
		},
		descriptionMaxLength() {
			return 1024 * 4
		},
		nameMaxLength() {
			return 128
		},
		startDateTimeText() {
			return this.timestamp.start.date + ' ' + this.timestamp.start.time
		},
		endDateTimeText() {
			if (this.eventType) {
				return ['interview', 'conference', 'presentation', 'workshop', 'seminar'].includes(this.eventType.code)
					? this.timestamp.start.date + ' ' + this.timestamp.end.time
					: this.timestamp.end.date + ' ' + this.timestamp.end.time
			} else {
				return this.timestamp.end.date + ' ' + this.timestamp.end.time
			}
		},
		timespanDivisible() {
			if (this.eventType) {
				return this.eventType.code == 'interview' && this.duration > 0
					? ((this.$moment.utc(this.startDateTimeText) - this.$moment.utc(this.endDateTimeText)) / (1000 * 60)) % this.duration == 0
					: false
			}
			return false
		},
		...mapGetters({
			event: 'events/event',
			eventTypes: 'events/eventTypes',
			company: 'companies/company'
		})
	},
	created() {
		this.fetchEventTypes()
		if (this.$route.query.reuse && this.$route.query.reuse == true && this.event) {
			this.eventType = this.event.type
			this.title = this.event.title
			this.location = this.event.info.location
			this.website = this.event.info.website
			this.capacity = this.event.attendances.maxCapacity
			this.description = this.event.info.description
			this.timestamp.start = {
				date: this.$moment.utc(this.event.info.timestamp.start).format('YYYY-MM-DD'),
				time: this.$moment.utc(this.event.info.timestamp.start).format('HH:mm:ss')
			}
			this.timestamp.end = {
				date: this.$moment.utc(this.event.info.timestamp.end).format('YYYY-MM-DD'),
				time: this.$moment.utc(this.event.info.timestamp.end).format('HH:mm:ss')
			}
		}
		this.loading = false
	},
	methods: {
		roundStartTime() {
			this.timestamp.start.time = roundTime(this.timestamp.start.time, 5)
		},
		roundEndTime() {
			this.timestamp.end.time = roundTime(this.timestamp.end.time, 5)
		},
		validateType() {
			this.$refs.formType.validate()
			this.formTypeSubmitted = true
			if (this.validType) {
				this.stepperIndex++
			}
			window.scrollTo(0, 0)
		},
		validateEvent() {
			this.$refs.formEvent.validate()
			this.formEventSubmitted = true
			if (this.validEvent) {
				this.stepperIndex++
			}
			window.scrollTo(0, 0)
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		onLogoUpload() {
			new Promise((resolve, reject) => {
				new Compressor(this.logo, {
					quality: 0.9,
					success: resolve,
					error: reject
				})
			})
				.then((result) => {
					this.logoCompressed = result
					this.logoUrl = URL.createObjectURL(this.logoCompressed)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callUpdateLogo() {
			this.$refs.formType.validate()
			this.$refs.formEvent.validate()
			this.$refs.formSupplementary.validate()
			this.$refs.formLogo.validate()
			if (!this.validType || !this.validEvent || !this.validSupplementary || !this.validLogo) return
			this.loading = true
			const formData = new FormData()
			formData.append('logo', this.logoCompressed, this.logoCompressed.name)
			return this.updateEventLogo({ eventID: this.event.id, formData }).then(() => {
				this.logo = null
				this.logoCompressed = null
				this.logoUrl = null
			})
		},
		callCreateEvent() {
			this.$refs.formType.validate()
			this.$refs.formEvent.validate()
			this.$refs.formSupplementary.validate()
			this.$refs.formLogo.validate()
			this.formTypeSubmitted = true
			this.formEventSubmitted = true
			if (!this.validType || !this.validEvent || !this.validSupplementary) return
			this.loading = true
			this.createEvent({
				type: this.eventType,
				title: this.title,
				companyName: this.companyName,
				location: this.location,
				website: this.website,
				description: this.description,
				startDate: this.timestamp.start.date,
				endDate: ['interview', 'conference', 'presentation', 'workshop', 'seminar'].includes(this.eventType.code)
					? this.timestamp.start.date
					: this.timestamp.end.date,
				startTime: this.timestamp.start.time,
				endTime: this.timestamp.end.time,
				capacity: this.capacity,
				duration: this.duration,
				people: this.people
			}).then(({ success, event }) => {
				if (success && this.logoCompressed) {
					this.callUpdateLogo().then(() => {
						this.loading = false
						this.$router.push({ name: 'Event', params: { id: event.id } })
					})
				} else if (success) {
					this.$router.push({ name: 'Event', params: { id: event.id } })
				} else {
					this.loading = false
				}
			})
		},
		...mapActions('events', ['fetchEventTypes', 'createEvent', 'updateEventLogo'])
	}
}
</script>

<style scoped></style>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
